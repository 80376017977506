<template>
  <section>
    <div class="block-url">
      <h4>紹介URL</h4>
      <p class="des-block">SNSやブログからのリンクとして貼る際にお使いください。<br class="sp">紹介コードは自動的に入力されます。</p>
      <div class="wrap-copy">
        <input ref="url1"
               :value='currentURL + "?invite="+ $store.state.auth.profile.ref_code'/>
        <button @click="copyText('url1', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
    <div class="block-url">
      <h4>紹介コード</h4>
      <p class="des-block">新規入会フォームに、お友達ご自身で入力いただく際のコードです。<br>
        入会後は入力できませんのでご注意ください。</p>
      <div class="wrap-copy">
        <input ref="url2"
               :value='$store.state.auth.profile.ref_code'/>
        <button @click="copyText('url2', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { ClipboardIcon } from 'vue-feather-icons'
import CommonMixin from '@/mixins/common.mixin'

export default {
  name: 'Tab2',

  components: {
    ClipboardIcon
  },

  mixins: [CommonMixin],

  data () {
    return {
      currentURL: window.location.origin
    }
  }
}
</script>

<style lang="scss" scoped>
.block-url {
  padding: 24px;
  border-radius: 6px;
  background: #FAFAFA;
  margin-bottom: 8px;
  @media #{$info-phone} {
    padding: 20px 18px;
  }
  h4 {
    font-family: $font-zen-kaku;
    font-weight: bold;
    color: #7C934C;
    @include font-size(20px);
    margin-bottom: 16px;
    @media #{$info-phone} {
      text-align: center;
      font-weight: normal;
      margin-bottom: 10px;
    }
  }
  .des-block {
    @include font-size(16px);
    margin-bottom: 19px;
    @media #{$info-phone} {
      font-size: 16px;
      margin-bottom: 45px;
    }
  }
}
</style>
