<template>
  <section>
    <div class="block-url-img">
      <h4>640×480ピクセル</h4>
      <!-- replace character \' in url define in data -->
      <img :src="urlIMG1.replaceAll('\'', '')" alt="">
      <div class="wrap-copy">
        <input ref="url1"
               :value='"<a href=" + urlInvite + ">"
               + "<img src=" + urlIMG1 + "></a>"' />
        <button @click="copyText('url1', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
    <div class="block-url-img">
      <h4>728×90ピクセル</h4>
      <!-- replace character \' in url define in data -->
      <img :src="urlIMG2.replaceAll('\'', '')" alt="">
      <div class="wrap-copy">
        <input ref="url2"
               :value='"<a href=" + urlInvite + ">"
               + "<img src=" + urlIMG2 + "></a>"' />
        <button @click="copyText('url2', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
    <div class="block-url-img">
      <h4>468×60ピクセル</h4>
      <!-- replace character \' in url define in data -->
      <img :src="urlIMG3.replaceAll('\'', '')" alt="">
      <div class="wrap-copy">
        <input ref="url3"
               :value='"<a href=" + urlInvite + ">"
               + "<img src=" + urlIMG3 + "></a>"' />
        <button @click="copyText('url3', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { ClipboardIcon } from 'vue-feather-icons'
import CommonMixin from '@/mixins/common.mixin'

export default {
  name: 'Tab3',

  components: {
    ClipboardIcon
  },

  mixins: [CommonMixin],

  data () {
    return {
      urlInvite: `'${window.location.origin + '?invite=' + this.$store.state.auth.profile.ref_code}'`,
      urlIMG1: '\'https://s3.ap-northeast-1.amazonaws.com/stg.chibijob.com/static/images/banners/sBksGxt7w1_20221121111602.jpg\'',
      urlIMG2: '\'https://s3.ap-northeast-1.amazonaws.com/stg.chibijob.com/static/images/banners/vEf9GGqAwq_20221121111849.jpg\'',
      urlIMG3: '\'https://s3.ap-northeast-1.amazonaws.com/stg.chibijob.com/static/images/banners/iZl1pEue26_20221121111801.jpg\''
    }
  }
}
</script>

<style lang="scss" scoped>
.block-url-img {
  padding: 24px;
  border-radius: 6px;
  background: #FAFAFA;
  @media #{$info-phone} {
    padding: 20px 18px 23px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  h4 {
    color: #7C934C;
    @include font-size(20px);
    text-align: center;
    margin-bottom: 24px;
    @media #{$info-phone} {
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 12px;
    }
  }
  img {
    display: block;
    margin:auto auto 24px;
    @media #{$info-phone} {
      margin: auto auto 18px;
    }
  }
}
</style>
