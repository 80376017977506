<template>
  <div id="advertiser">
    <button
      class="btn-modal-recommend"
      v-html="'動画を見てシェア'"
      @click="onInitVideo"
    />
  </div>
</template>

<script>
export default {
  name: 'VideoAdvertise',

  data () {
    return {
      placementId: 'Yce2cipwYKVz',
      rootTimeout: 20000
    }
  },

  computed: {
    videoLoadingTimeout () {
      const timeOutStorage = process.env.VUE_APP_VIDEO_ADVERTISE_LOADING_TIMEOUT || this.rootTimeout
      const timeoutVal = Number(timeOutStorage)
      return !isNaN(timeoutVal) ? timeoutVal : this.rootTimeout
    }
  },

  methods: {
    onInitVideo () {
      this.$emit('adVideoInit')
      this.$emit('adStartWaiting')

      setTimeout(() => {
        this.$emit('adDoneWaiting')
      }, this.videoLoadingTimeout)

      try {
        const el = document.createElement('div')
        document.body.append(el)

        const player = (window.playerPro = window.playerPro || [])
        player.push({
          id: this.placementId,
          after: el,
          init: (api) => {
            if (!api) return

            this.$emit('adAPI', api)

            api.on('AdStarted', () => {
              this.$emit('adVideoStarted')
            })

            api.on('AdVideoStart', () => {
              api.setAdVolume(0)
            })

            api.on('AdVideoComplete', () => {
              this.$emit('adVideoComplete')
              api.remove()

              const videoDisplay = document.querySelector(`[data-id=${this.placementId}]`)
              if (!videoDisplay) return

              videoDisplay.remove()
            })
          }
        })
      } catch {
        this.$emit('adError')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
