<template>
  <main class="invite-friend">
    <!-- content home page -->
    <div class="home-content container main-has-sidebar"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}">
      <!-- sidebar -->
      <sidebar class="pc"/>

      <div class="main-content">
        <!-- block title invite friend -->
        <div class="block-title">
          <h2 class="title-green">Invitation</h2>
          <span class="sub-green">お友達紹介</span>
          <p>ChibiJobをお友達にシェアしてポイントゲット！<br class="pc">
            更にお友達が入会してくれたら追加でポイントゲット！！<br class="pc">
            お友達紹介でお得にポイントを貯めちゃおう♪</p>
        </div>

        <!-- benefits when inviting friends -->
        <div class="benefit">
          <!-- repost ads -->
          <div class="content-benefit">
            <h3>シェアする</h3>
            <p class="receive-point"><img src="../assets/images/5-point.svg" alt="number point"><span>／回</span></p>
            <h4>動画を見てシェアするだけでもれなくポイントゲット！</h4>
            <p class="des-benefit">1日1回、動画を見てX(旧Twitter)シェア限定で<strong>5ポイント</strong>をプレゼント！</p>
          </div>

          <!-- invite friend -->
          <div class="content-benefit benefit-invite-friend">
            <h3>お友達が入会</h3>
            <p class="receive-point"><img src="../assets/images/100-point.png" alt="number point"><span>／人</span></p>
            <h4>紹介したお友達の入会で更にポイントゲット！</h4>
            <p class="des-benefit">お友達があなたの紹介から入会してくれた場合は、更に<strong>100ポイント</strong>をプレゼント！</p>
          </div>
        </div>

        <!-- button register and login -->
        <div class="block-btn" v-if="!token">
          <!-- btn registration free -->
          <a @click.prevent="openPageNative('Register')" class="btn-registration">無料会員登録はこちら</a>

          <!-- link login -->
          <a @click.prevent="openPageNative('Login')" class="btn-login">すでに会員の方はこちらからログイン <ChevronRightIcon size="24"/></a>
        </div>

        <!-- tab -->
        <div class="common-tab" id="header-tab" v-if="token">
          <!-- menu tab -->
          <ul class="menu-tab">
            <li :class="{'active': currentTab === item.id}"
                v-for="item in menuTab"
                :key="item.id"
                @click="currentTab = item.id">
              <span>{{ item.id }} ／</span>{{ item.name }}
            </li>
          </ul>

          <!-- content tab -->
          <div class="wrap-content-tab">
            <component :is="'Tab' + currentTab"
                       @notiShare="flagNotifyShare = true"/>
          </div>

          <!-- button reference to tab -->
          <div class="button-ref">
            <!-- prev tab -->
            <button class="btn-pre btn-ref"
                    @click="--currentTab && scrollToTab()"
                    v-if="currentTab !== 1">
              <ChevronLeftIcon />{{ menuTab[currentTab - 2].name }}へ
            </button>

            <!-- line -->
            <span class="line"></span>

            <!-- next tab -->
            <button class="btn-next btn-ref"
                    @click="++currentTab && scrollToTab()"
                    v-if="currentTab !== 3">
              {{ menuTab[currentTab].name }}へ <ChevronRightIcon />
            </button>
          </div>
        </div>

        <!-- measure -->
        <div class="block-measure" v-if="token">
          <h4>友達紹介注意事項</h4>
          <p>
            下記注意事項を参考に、利用規約に基づいてお友達紹介サービスをご利用くださいませ。<br><br>
            シェアによるポイント付与は以下の条件がございます。<br>
            ・X(旧Twitter)のシェアのみ対象<br>
            ・1日1シェアまで<br>
            <br>
            お友達に登録頂くメールアドレスの種類によって、ポイント付与方法が異なります。</p>
            <br>
            <p>■キャリアメールアドレスの場合<br>
            お友達が登録してから1週間後にポイント付与となります。<br>
            -----<br>
            対象となるキャリアメールアドレス一覧<br>
            -----</p>
          <div>
            <ul>
              <li>
                <span>ezweb.ne.jp</span>
                <span>emnet.ne.jp</span>
              </li>
              <li>
                <span>biz.ezweb.ne.jp </span>
                <span>emobile.ne.jp</span>
              </li>
              <li>
                <span>augps.ezweb.ne.jp</span>
                <span>emobile-s.ne.jp</span>
              </li>
              <li>
                <span>ido.ne.jp</span>
                <span>ymobile1.ne.jp </span>
              </li>
              <li>
                <span>uqmobile.jp</span>
                <span>ymobile.ne.jp</span>
              </li>
              <li>
                <span>au.com</span>
                <span>yahoo.ne.jp</span>
              </li>
              <li>
                <span>biz.au.com</span>
                <span>pdx.ne.jp</span>
              </li>
              <li>
                <span>docomo.ne.jp</span>
                <span>willcom.com</span>
              </li>
              <li>
                <span>mopera.net</span>
                <span>wcm.ne.jp </span>
              </li>
              <li>
                <span></span>
                <span>y-mobile.ne.jp</span>
              </li>
            </ul>
            <p>----</p>
             <p>■上記以外のメールアドレス（フリーアドレス）の場合<br>
              お友達が180日以内に300ポイント以上獲得すると、ポイント付与となります。<br>
              180日を過ぎると対象外となりますので、ご注意くださいませ。
            </p>
            <p>下記項目に当てはまる場合は、予告なく強制退会もしくは、ポイントの没収となる可能性がございます。<br>
              <br>
              ・スパム利用とみなされる不正なご紹介<br>
              ・虚偽内容でのご紹介<br>
              ・ご利用の意思がないお友達が多くご登録されている場合<br>
              ・リスティング広告を使用して紹介している場合<br>
              ・当サイトイメージを著しく損なう内容/表現方法での紹介を行なった場合<br>
              ・交換条件を提示し登録を促している場合<br>
              ・サービスのご紹介以外の文言にて登録を促している場合
            </p>
            <p>過去にChibiJobに登録したことがあるお友達は対象外となります。</p>

          </div>
        </div>
      </div>
    </div>

    <!-- MODAL ERROR WHEN SHARE TWITTER -->
    <Modal @close-modal="handleCloseError"
           class="modal-center modal-exchange-error"
           v-if="flagErrorShare">
      <div slot="body">
        <p>エラーが発生しました。<br>時間をおいてから再度お試しください。</p>
      </div>
    </Modal>

    <!-- MODAL NOTIFY SUCCESS WHEN SHARED TWITTER -->
    <Modal @close-modal="handleCloseModal"
           class="modal-center modal-notify cate-pink"
           v-if="flagNotifyShare">
      <div slot="body" class="responsive-height">
        <div class="block-vertical">
          <ListItem :list="listRecommendModal" titleModal="おともだちへのご紹介ありがとうございます！ <br> すぐにチェックして、ポイントGET！！！ <br> ↓ ↓ ↓ ↓" hasBackground mode="vertical" isChangeBackground isInModal keepModal/>
        </div>
        <p class="content-success">X(旧Twitter)へポストの送信に成功しました。</p>
        <button
               @click="handleCloseModal"
                v-html="'閉じる'"
                class="btn-modal-recommend" />
      </div>
    </Modal>
  </main>
</template>

<script>
import Sidebar from '@/layouts/Sidebar'
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons'
import Tab1 from '@/components/invite-friend/Tab1'
import Tab2 from '@/components/invite-friend/Tab2'
import Tab3 from '@/components/invite-friend/Tab3'
import ListItem from '@/components/ListItem.vue'

import { mapState, mapActions } from 'vuex'
import store from '@/store'
import Modal from '@/components/advertising-detail/Modal'
import Common from '@/mixins/common.mixin'

export default {
  name: 'InviteFriend',

  components: {
    Modal,
    Sidebar,
    ChevronRightIcon,
    ChevronLeftIcon,
    Tab1,
    Tab2,
    Tab3,
    ListItem
  },

  mixins: [Common],

  computed: {
    ...mapState('auth', ['token']),
    ...mapState('category', ['categoryListTitle']),
    ...mapState('shareSns', ['flagErrorShare']),
    ...mapState('auth', ['profile']),
    ...mapState('advertises', ['listRecommendModal', 'isKeepModal', 'isShare', 'advIdOpenShared']),

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  data () {
    return {
      currentTab: 1,
      menuTab: [
        {
          id: 1,
          name: 'SNS'
        },
        {
          id: 2,
          name: '紹介URL・コード'
        },
        {
          id: 3,
          name: 'バナー素材'
        }
      ],
      flagNotifyShare: false
    }
  },
  created () {
    // check show modal notify share complete when from page call back
    const notifyShare = localStorage.getItem('notifySuccess') || null
    if (notifyShare || (this.isKeepModal && this.isShare && !this.advIdOpenShared && !this.flagErrorShare)) {
      this.flagNotifyShare = true
      localStorage.removeItem('notifySuccess')
      store.dispatch('advertises/getListRecommendModal')
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  methods: {
    ...mapActions('shareSns', ['setTwitterError']),

    scrollToTab () {
      const header = document.getElementById('header-tab')
      const yCoordinate = header.getBoundingClientRect().top + window.pageYOffset
      let yOffset = 0

      // check device SP plus offset top is covered by header
      if (window.matchMedia('(max-width: 767px)').matches) {
        yOffset -= 45
      }

      window.scrollTo({
        top: yCoordinate + yOffset,
        behavior: 'smooth'
      })
    },

    handleCloseError () {
      this.setTwitterError(false)
    },

    handleCloseModal () {
      this.flagNotifyShare = false
      this.$store.dispatch('advertises/resetModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-content {
  display: flex;
  padding-top: 95px;
  padding-bottom: 96px;
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 130px;
    }
  }
  &.padding-webview {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .main-content {
    flex: 1;
    .block-title {
      padding: 0;
      margin-bottom: 66px;
      @media #{$info-phone} {
        margin-bottom: 30px;
      }
      @media #{$info-phone} {
        margin-bottom: 31px;
      }
      h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        &.only-pc {
          display: flex;
          @media #{$info-phone} {
            display: none;
          }
        }
        &.only-sp {
          display: none;
          @media #{$info-phone} {
            display: flex;
          }
        }
        b {
          margin: auto 5px;
        }
      }
      p {
        @include font-size(18px);
      }
    }
    .benefit {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin-bottom: 72px;
      @media #{$info-phone} {
        flex-direction: column;
      }
      .content-benefit {
        position: relative;
        width: calc(50% - 12px);
        border-radius: 6px;
        border: 6px solid #D9D8D5;
        text-align: center;
        padding: 36px 30px 37px;
        font-family: $font-family-title;
        @media #{$info-tablet-vertical} {
          padding: 25px 15px;
          width: calc(50% - 5px);
        }
        @media #{$info-phone} {
          width: 100%;
          margin-bottom: 7px;
        }
        &.benefit-invite-friend {
          border: 6px solid #E4D9AB;
          h4 {
            color: #A59659;
          }
          .des-benefit {
            strong {
              color: #A59659;
            }
          }
        }
        h3 {
          @include font-size(24px);
          position: relative;
          line-height: 1;
          margin-bottom: 25px;
          display: inline-block;
          font-weight: normal;
          @media #{$info-phone} {
            font-size: 24px;
            margin-bottom: 21px;
          }
          &:before, &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 22px;
            background: #282828;
            transform: rotateZ(-30deg);
            bottom: 0;
            left: -27px;
            @media #{$info-phone} {
              height: 20px;
            }
          }
          &:after {
            transform: rotateZ(30deg);
            left: unset;
            right: -27px;
          }
        }
        .receive-point {
          margin-left: 20px;
          margin-bottom: 24px;
          @media #{$info-phone} {
            margin-left: 35px;
          }
          span {
            margin-bottom: 20px;
            display: inline-block;
            @media #{$info-phone} {
              font-size: 20px;
            }
          }
        }
        h4 {
          font-weight: normal;
          @include font-size(21px);
          color: #898882;
          margin-bottom: 29px;
        }
        .des-benefit {
          font-family: $font-default;
          @include font-size(18px);
          text-align: left;
          @media #{$info-phone} {
            font-size: 16px;
          }
          span {
            font-family: $font-family-title-B;
          }
          strong {
            font-family: $font-family-title-B;
            color: #898882;
          }
        }
      }
    }
    .block-btn {
      font-family: $font-family-title;
      text-align: center;
      .btn-registration {
        max-width: 456px;
        width: 100%;
        text-align: center;
        padding: 18px 0 22px;
        background: #7C934C;
        border-radius: 6px;
        color: #ffffff;
        margin: auto;
        @include font-size(20px);
        display: inline-block;
        @media #{$info-phone} {
          font-size: 20px
        }
      }
      .btn-login {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #282828;
        @include font-size(14px);
        margin-top: 25px;
        @media #{$info-phone} {
          margin-top: 20px;
          font-size: 16px;
        }
        &:hover {
          text-decoration: none;
        }
        svg {
          margin-left: 47px;
          @media #{$info-phone} {
            margin-left: 23px;
          }
        }
      }
    }
    .common-tab {
      margin-top: 96px;
      @media #{$info-phone} {
        margin-top: 70px;
      }
      .menu-tab {
        li {
          &:nth-child(1) {
            @media #{$info-phone} {
              width: 60px;
            }
          }
          &:nth-child(2) {
            @media #{$info-phone} {
              width: 166px;
            }
          }
          &:nth-child(3) {
            @media #{$info-phone} {
              width: 113px;
            }
          }
        }
      }
      .button-ref {
        @media #{$info-phone} {
          margin-bottom: 90px;
        }
        .btn-next img {
          @media #{$info-phone} {
            margin-left: 20px;
          }
        }
      }
    }
    .block-measure {
      h4 {
        font-weight: normal;
        font-family: $font-family-title;
        @include font-size(24px);
        margin-bottom: 37px;
        text-align: center;
        color: #7C934C;
        @media #{$info-phone} {
          font-size: 20px;
          margin-bottom: 33px;
        }
      }
      p {
        margin-bottom: 20px;
        @media #{$info-phone} {
          font-size: 14px;
        }
      }
      ul {
        @include font-size(16px);
        @media #{$info-phone} {
          font-size: 14px;
        }
        li {
          span {
            &:first-child {
              width: 260px;
              display: inline-block;
              @media #{$info-phone} {
                width: 160px;
              }
            }
          }
        }
      }
    }
  }
}
.modal-notify {
  @include font-size(24px);
  font-weight: bold;
}

.content-success {
  margin-top: 20px;
  @media #{$info-phone} {
    font-size: 18px
  }
  @media (max-height:600px) {
    font-size: 18px
  }
}
</style>
